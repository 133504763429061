import * as React from "react"
import { useEffect, useState } from "react"
import { Link } from "gatsby"
import moment from "moment"
import {
  LabelWhite,
  SortingWhite,
  TruckWhite,
  DoorstepWhite,
  BoxFast,
} from "../components/svg"
import { Seo, Layout, Section, Container, Grid, Col } from "../components"
import { Modal } from "../components/Modal"
import { getTrackShipment } from "../action/trackShipmentAction"

function ShipmentTracker(props) {
  const { location } = props
  const [responseData, setResponseData] = useState([])
  const [shipment_id, setID] = useState("")
  const [value, setValue] = useState([])
  const [showDiv, setShowDiv] = useState(false)
  const [showError, setShowError] = useState(false)
  const [isShipmentInTransit, setIsShipmentInTransit] = useState(false)
  const [showPhotoProofDelivery, setShowPhotoProofDelivery] = useState(false)
  let trackingEvents = new Map()

  const fromIndexbtn = (id, include_photo = false) => {
    getTrackShipment(id, include_photo)
      .then(async res => {
        setShowError(false)
        setResponseData(res?.data)
        setValue(res?.data?.tracking_events)
        setShowDiv(true)
        res?.data?.tracking_events.map((ele, i) => {
          const existingEvent = trackingEvents.get(ele.carrier_timestamp_at)
          if (
            existingEvent &&
            existingEvent.event_code === "Package Delivery Attempt" &&
            ele.event_code === "Package Delivered"
          ) {
            trackingEvents.set(ele.carrier_timestamp_at, ele)
          } else if (
            !existingEvent ||
            existingEvent.event_code !== "Package Delivered"
          ) {
            trackingEvents.set(ele.carrier_timestamp_at, ele)
          }
        })
        var mapAsc = []
        trackingEvents.forEach((value, key) => {
          mapAsc.push(value)
        })
        setValue(mapAsc)
      })
      .catch(err => {
        console.log(err)
        setShowError(true)
      })
  }

  useEffect(() => {
    const id = location?.state?.trackingId
      ? location?.state?.trackingId
      : location.search.split("=")[1]
      ? location.search.split("=")[1]
      : ""
    console.log("tracking page", id)
    if (id) {
      setID(id)
      fromIndexbtn(id, true)
    } else {
      setID("")
      setShowDiv(false)
    }
  }, [location?.state?.trackingId])

  // useEffect(() => {
  //   console.log("shipment id", shipment_id)
  //   fromIndexbtn(shipment_id)
  //   setShowDiv(true)
  // }, [shipment_id])

  const shipmentStatus = responseData?.status_code
  const photoProofOfDelivery = responseData?.delivery_proof || "" // remove this image url when we get the details of the photo

  let lastItem
  if (value && value.length && value.length > 0) {
    lastItem = value?.reduce((prev, cur) =>
      cur.carrier_timestamp_at > prev.carrier_timestamp_at ? cur : prev
    )
  }
  useEffect(() => {
    const inTransitEvents = [
      "Shipment Loaded",
      "Shipment Unloaded",
      "Shipment Sorted",
      "Shipment Received",
      "Shipment In Transit",
      "Package Loaded",
      "Package UnLoaded",
      "Package Sorted",
      "Package Received",
      "Package In Transit",
    ]

    if (
      inTransitEvents.includes(lastItem?.event_code) ||
      inTransitEvents.includes(shipmentStatus)
    ) {
      setIsShipmentInTransit(true)
    } else {
      setIsShipmentInTransit(false)
    }
  }, [lastItem])

  let deliveryAddress = ""
  if (
    (lastItem?.event_code === "Shipment Delivered" ||
      lastItem?.event_code === "Package Delivered") &&
    lastItem?.occured_at
  ) {
    deliveryAddress = lastItem?.occured_at
  }

  // value.sort(function (a, b) {
  //   return new Date(b.carrier_timestamp_at) - new Date(a.carrier_timestamp_at)

  const handleClick = () => {
    if (shipment_id != "") {
      setShowDiv(false)
      fromIndexbtn(shipment_id, true)
    }
  }

  const showPhotoOfDelivery = () => {
    fromIndexbtn(shipment_id, true)
    setShowPhotoProofDelivery(true)
  }
  const renderDeliverdiv = () => {
    if (
      shipmentStatus === "Label Created" ||
      shipmentStatus === "Labels Created"
    ) {
      return (
        <Container
          padding="none"
          classes="px-12 py-20 sm:py-16 md:py-12 lg:py-0 lg:px-0"
        >
          <div className="mx-auto xl:w-3/4 xxl:w-2/4 sm:p-8 lg:p-12">
            <div className="mx-auto text-accent-light lg:text-4xl sm:text-2xl lg:p-4 font-bold text-center">
              Shipment Information
            </div>
            <div className="md:p-5 ">
              <div className="md:mx-4 md:p-4 mt-16 ">
                <div className="flex items-center">
                  <div className="flex items-center text-white relative">
                    <div className="h-8 w-8 absolute mb-20">
                      <LabelWhite />
                    </div>

                    <div className="rounded-full transition duration-500 ease-in-out h-7 w-7 border-8 border-accent"></div>

                    <div className="tracker-text absolute top-0 w-32 font-medium text-white">
                      Label Created
                    </div>
                  </div>

                  <div className=" flex-auto border-4 border-white transition duration-500 ease-in-out -500 "></div>

                  <div className="flex items-center text-white relative">
                    <div className="h-8 w-8 absolute mb-20">
                      <SortingWhite />
                    </div>
                    <div className="rounded-full transition duration-500 ease-in-out h-7 w-7 border-8 border-white"></div>
                    <div className="tracker-text absolute top-0 w-32 font-medium text-white">
                      In Transit
                    </div>
                  </div>

                  <div className="flex-auto border-4 border-white transition duration-500 ease-in-out -500 "></div>
                  <div className="flex items-center text-white relative">
                    <div className="h-8 w-8 absolute mb-20">
                      <TruckWhite />
                    </div>
                    <div className=" rounded-full  transition duration-500  ease-in-out  h-7 w-7 border-8 border-white  "></div>
                    <div className="tracker-text absolute top-0 w-32 font-medium text-white">
                      Out for Delivery
                    </div>
                  </div>

                  <div className=" flex-auto border-t-8 transition duration-500 ease-in-out border-white "></div>

                  <div className="flex items-center text-white relative">
                    <div className="h-8 w-8 absolute mb-28">
                      <DoorstepWhite />
                    </div>
                    <div className=" rounded-full  transition duration-500  ease-in-out  h-7 w-7 border-8 border-white  "></div>
                    <div className="tracker-text absolute top-0 w-32 font-medium text-white">
                      Delivered
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mx-auto flex justify-center">
              <BoxFast className="w-36 relative top-28 sm:top-32 md:top-28 lg:top-20" />
            </div>
          </div>
        </Container>
      )
    }
    if (isShipmentInTransit) {
      return (
        <Container
          padding="none"
          classes="px-12 py-20 sm:py-16 md:py-12 lg:py-0 lg:px-0"
        >
          <div className="mx-auto xl:w-3/4 xxl:w-2/4 sm:p-8 lg:p-12">
            <div className="mx-auto text-accent-light lg:text-4xl sm:text-2xl lg:p-4 font-bold text-center">
              Shipment Information
            </div>
            <div className="md:p-5 ">
              <div className="md:mx-4 md:p-4 mt-16 ">
                <div className="flex items-center">
                  <div className="flex items-center text-white relative">
                    <div className="h-8 w-8 absolute mb-20">
                      <LabelWhite />
                    </div>

                    <div className="  rounded-full transition duration-500 ease-in-out h-7 w-7 border-8 border-accent "></div>

                    <div className="tracker-text absolute top-0 w-32 font-medium text-white">
                      Label Created
                    </div>
                  </div>

                  <div className=" flex-auto border-4 border-accent-light transition duration-500 ease-in-out -500 "></div>

                  <div className="flex items-center text-white relative">
                    <div className="h-8 w-8 absolute mb-20">
                      <SortingWhite />
                    </div>
                    <div className="rounded-full transition duration-500 ease-in-out h-7 w-7 border-8 border-accent"></div>
                    <div className="tracker-text absolute top-0 w-32 font-medium text-white">
                      In Transit
                    </div>
                  </div>

                  <div className="flex-auto border-4 border-white transition duration-500 ease-in-out -500 "></div>
                  <div className="flex items-center text-white relative">
                    <div className="h-8 w-8 absolute mb-20">
                      <TruckWhite />
                    </div>
                    <div className=" rounded-full  transition duration-500  ease-in-out  h-7 w-7 border-8 border-white  "></div>
                    <div className="tracker-text absolute top-0 w-32 font-medium text-white">
                      Out for Delivery
                    </div>
                  </div>

                  <div className=" flex-auto border-t-8 transition duration-500 ease-in-out border-white "></div>

                  <div className="flex items-center text-white relative">
                    <div className="h-8 w-8 absolute mb-28">
                      <DoorstepWhite />
                    </div>
                    <div className=" rounded-full  transition duration-500  ease-in-out  h-7 w-7 border-8 border-white  "></div>
                    <div className="tracker-text absolute top-0 w-32 font-medium text-white">
                      Delivered
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mx-auto flex justify-center">
              <BoxFast className="w-36 relative top-28 sm:top-32 md:top-28 lg:top-20" />
            </div>
          </div>
        </Container>
      )
    }
    if (shipmentStatus === "Shipment Delivered") {
      return (
        <Container
          padding="none"
          classes="px-12 py-20 sm:py-16 md:py-12 lg:py-0 lg:px-0"
        >
          <div className="mx-auto xl:w-3/4 xxl:w-2/4 sm:p-8 lg:p-12">
            <div className="mx-auto text-accent-light lg:text-4xl sm:text-2xl lg:p-4 font-bold text-center">
              Shipment Information
            </div>
            <div className="md:p-5 ">
              <div className="md:mx-4 md:p-4 mt-16 ">
                <div className="flex items-center">
                  <div className="flex items-center text-white relative">
                    <div className="h-8 w-8 absolute mb-20">
                      <LabelWhite />
                    </div>

                    <div className="  rounded-full transition duration-500 ease-in-out h-7 w-7 border-8 border-accent "></div>

                    <div className="tracker-text absolute top-0 w-32 font-medium text-white">
                      Label Created
                    </div>
                  </div>

                  <div className=" flex-auto border-4 border-accent-light transition duration-500 ease-in-out -500 "></div>

                  <div className="flex items-center text-white relative">
                    <div className="h-8 w-8 absolute mb-20">
                      <SortingWhite />
                    </div>
                    <div className="rounded-full transition duration-500 ease-in-out h-7 w-7 border-8 border-accent"></div>
                    <div className="tracker-text absolute top-0 w-32 font-medium text-white">
                      In Transit
                    </div>
                  </div>

                  <div className="flex-auto border-4 border-accent-light transition duration-500 ease-in-out -500 "></div>
                  <div className="flex items-center text-white relative">
                    <div className="h-8 w-8 absolute mb-20">
                      <TruckWhite />
                    </div>
                    <div className=" rounded-full  transition duration-500  ease-in-out  h-7 w-7 border-8 border-accent  "></div>
                    <div className="tracker-text absolute top-0 w-32 font-medium text-white">
                      Out for Delivery
                    </div>
                  </div>

                  <div className=" flex-auto border-t-8 transition duration-500 ease-in-out border-accent-light "></div>

                  <div className="flex items-center text-white relative">
                    <div className="h-8 w-8 absolute mb-28">
                      <DoorstepWhite />
                    </div>
                    <div className=" rounded-full  transition duration-500  ease-in-out  h-7 w-7 border-8 border-accent  "></div>
                    <div className="tracker-text absolute top-0 w-32 font-medium text-white">
                      Delivered
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mx-auto flex justify-center">
              <BoxFast className="w-36 relative top-28 sm:top-32 md:top-28 lg:top-20" />
            </div>
          </div>
        </Container>
      )
    } else {
      return (
        <Container
          padding="none"
          classes="px-12 py-20 sm:py-16 md:py-12 lg:py-0 lg:px-0"
        >
          <div className="mx-auto xl:w-3/4 xxl:w-2/4 sm:p-8 lg:p-12">
            <div className="mx-auto text-accent-light lg:text-4xl sm:text-2xl lg:p-4 font-bold text-center">
              Shipment Information
            </div>
            <div className="md:p-5 ">
              <div className="md:mx-4 md:p-4 mt-16 ">
                <div className="flex items-center">
                  <div className="flex items-center text-white relative">
                    <div className="h-8 w-8 absolute mb-20">
                      <LabelWhite />
                    </div>

                    <div className="  rounded-full transition duration-500 ease-in-out h-7 w-7 border-8 border-white "></div>

                    <div className="tracker-text absolute top-0 w-32 font-medium text-white">
                      Label Created
                    </div>
                  </div>

                  <div className=" flex-auto border-4 border-white transition duration-500 ease-in-out -500 "></div>

                  <div className="flex items-center text-white relative">
                    <div className="h-8 w-8 absolute mb-20">
                      <SortingWhite />
                    </div>
                    <div className="rounded-full transition duration-500 ease-in-out h-7 w-7 border-8 border-white"></div>
                    <div className="tracker-text absolute top-0 w-32 font-medium text-white">
                      In Transit
                    </div>
                  </div>

                  <div className="flex-auto border-4 border-white transition duration-500 ease-in-out -500 "></div>
                  <div className="flex items-center text-white relative">
                    <div className="h-8 w-8 absolute mb-20">
                      <TruckWhite />
                    </div>
                    <div className=" rounded-full  transition duration-500  ease-in-out  h-7 w-7 border-8 border-white  "></div>
                    <div className="tracker-text absolute top-0 w-32 font-medium text-white">
                      Out for Delivery
                    </div>
                  </div>

                  <div className=" flex-auto border-t-8 transition duration-500 ease-in-out border-white "></div>

                  <div className="flex items-center text-white relative">
                    <div className="h-8 w-8 absolute mb-28">
                      <DoorstepWhite />
                    </div>
                    <div className=" rounded-full  transition duration-500  ease-in-out  h-7 w-7 border-8 border-white  "></div>
                    <div className="tracker-text absolute top-0 w-32 font-medium text-white">
                      Delivered
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mx-auto flex justify-center">
              <BoxFast className="w-36 relative top-28 sm:top-32 md:top-28 lg:top-20" />
            </div>
          </div>
        </Container>
      )
    }
  }

  return (
    <>
      <Layout>
        <Seo
          title="Need To Track Your Package? Enter Your Tracking # Here"
          description="Enter your tracking number to see the status of your package. Our tracking system will your package's shipping history and current location."
          slug="shipment-tracker"
        />
        <Section padding="none">
          {/* codestart */}

          {/* search */}
          <Container padding="global-md" bgColor="bg-accent-light" classes="">
            <div className="flex items-center justify-center ">
              <div className="flex">
                <input
                  type="text"
                  value={shipment_id}
                  onChange={e => setID(e.target.value)}
                  className="px-2 lg:px-14 py-3 w-[75vw] sm:w-[50vw] md:w-80 outline-none rounded-l-3xl placeholder-black text-xs sm:text-sm lg:text-md text-center border-r-secondary"
                  placeholder="ENTER TRACKING NUMBER"
                />
                <button
                  onClick={() => handleClick()}
                  className="px-4 text-white rounded-r-3xl bg-secondary"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mr-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="5"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </button>
              </div>
            </div>
            {showError && (
              <p className="error-span">Tracking Number not Found</p>
            )}
          </Container>

          {/* Stepper For Shipment Info Start*/}
          <div className=" bg-secondary">{renderDeliverdiv()}</div>
          {/* Stepper For Shipment Info End*/}

          {/* Delivery Nav area start */}

          {showDiv ? (
            <>
              <Section padding="none">
                <Container
                  padding="global-md"
                  classes="mx-auto bg-white mt-20 p-4"
                >
                  <Grid classes="lg:grid-cols-2 lg:p-6">
                    <Col classes="space-y-6">
                      <div>
                        <div className="text-sm text-gray-600 font-bold">
                          Latest Update
                        </div>
                        <br />
                        <div className="lg:text-4xl sm:text-2xl text-black font-bold">
                          {/* Delivered */} {lastItem && lastItem?.event_code}
                        </div>
                      </div>
                      <div className="flex xl:mt-12 sm:mt-5">
                        <div>
                          <span className="text-sm text-green-500  ">
                            <strong>
                              {moment(
                                lastItem && lastItem?.carrier_timestamp_at
                              ).format("MMM Do YYYY")}
                            </strong>
                            <br />
                            {/* 02:48 AM */}
                            {moment(
                              lastItem && lastItem?.carrier_timestamp_at
                            ).format("LT")}
                          </span>
                        </div>

                        <div>
                          <p className="text-green-500 text-lg ml-12">
                            <div className="font-bold">
                              {/* Delivered */}

                              {/* {lastItem} */}
                              {lastItem && lastItem?.event_description}
                            </div>
                            {/* | RiverValue,NJ */}
                            {deliveryAddress}
                            <br />
                            {shipmentStatus === "Shipment Delivered" &&
                              photoProofOfDelivery && (
                                <button
                                  className="underline"
                                  onClick={showPhotoOfDelivery}
                                >
                                  View Proof of Delivery
                                </button>
                              )}
                          </p>
                        </div>
                      </div>
                      <div className="lg:mt-12 text-sm text-gray-500 xl:mt-24 sm:mt-8">
                        {/* Updated Oct 26 2021 | 2:04 PM EST */}Updated on{" "}
                        {moment(
                          lastItem && lastItem?.carrier_timestamp_at
                        ).calendar()}
                      </div>
                    </Col>
                  </Grid>
                </Container>
              </Section>
              {/* Photo proof of delivery*/}
              <Modal
                showModel={showPhotoProofDelivery}
                onModalClose={setShowPhotoProofDelivery}
              >
                <div className="flex text-base text-left transform transition w-full md:inline-block md:max-w-2xl md:px-4 md:my-8 md:align-middle">
                  <div className="w-full relative flex items-center bg-white overflow-hidden shadow-2xl ">
                    <img
                      src={photoProofOfDelivery}
                      alt={"Photo proof of delivery"}
                      className="object-center object-cover"
                    />
                    <button
                      type="button"
                      className="absolute hover:text-gray-500 right-1 text-gray-400 text-lg top-0"
                      onClick={() => setShowPhotoProofDelivery(false)}
                    >
                      <i className=" fas fa-times" />
                    </button>
                  </div>
                </div>
              </Modal>
            </>
          ) : null}

          {/* Delivery Nav area end */}

          {/* Table Design start */}
          {showDiv ? (
            <>
              <Section padding="none">
                <Container padding="global-md">
                  <div className="mx-auto  bg-white items-center justify-center mt-20 pb-2 lg:pb-6">
                    <div className=" mx-auto  bg-gray-600 p-5  items-center justify-center mt-5">
                      <center>
                        <h2 className="text-white text-2xl font-bold bg-dark-900 ">
                          Tracking History
                        </h2>
                      </center>
                    </div>
                    <Container classes="">
                      <Container
                        padding="none"
                        classes="border-b-2 space-y-6 py-12"
                      >
                        <div className="text-left text-gray-400 lg:text-xl">
                          <p className="text-secondary">
                            <strong>Status:</strong>{" "}
                            {responseData && responseData?.status_code}
                          </p>
                        </div>
                      </Container>
                      <Container padding="none">
                        <Container padding="none">
                          {value
                            .sort(function (a, b) {
                              return (
                                new Date(b.carrier_timestamp_at) -
                                new Date(a.carrier_timestamp_at)
                              )
                            })
                            .map(track => {
                              return (
                                <Grid classes="lg:grid-cols-12 pt-4 pb-3 border-b-2 last:border-none space-y-2 lg:space-y-0">
                                  <Col classes="col-span-3">
                                    <p className="text-gray-500">
                                      {moment(
                                        track.carrier_timestamp_at
                                      ).format("MM/DD/YYYY")}
                                    </p>
                                  </Col>
                                  <Col classes="col-span-6 font-bold">
                                    <p>{track.event_description}</p>
                                  </Col>
                                  <Col classes="col-span-3">
                                    {track.event_description ===
                                      "Package is delivered." && (
                                      <p> {track.occured_at},</p>
                                    )}
                                    <p>
                                      {track.city}, {track.state_province}
                                    </p>
                                  </Col>
                                </Grid>
                              )
                            })}
                        </Container>
                      </Container>
                    </Container>
                  </div>
                </Container>
              </Section>
            </>
          ) : null}

          {/* Table Design end */}
          <Container padding="none" classes="text-center my-24">
            <Link
              to="/contact/"
              className="px-10 py-6 text-white font-bold lg:text-3xl sm:text-2xl border-2 rounded-full bg-accent-light hover:bg-accent-dark hover:text-white transition duration-150 ease-in-out"
            >
              Shipment question? Contact us.
            </Link>
          </Container>
          {/* <div className="my-16">
            <center>
              <Link to="/contact/">
                <button
                  className="text-white p-6 xl:px-16 rounded-full lg:text-3xl font-bold"
                  style={{ backgroundColor: `#a4de02` }}
                >
                  Shipment question? Contact Us.
                </button>
              </Link>
            </center>
          </div> */}

          {/* codeEnd */}
        </Section>
      </Layout>
    </>
  )
}

export default ShipmentTracker
