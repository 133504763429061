import axios from "axios"

const API_URL = "https://api.shipx.com/"
const header_X_API_KEY = `aF9TE2FGyi6doZLWkeCpZ1bffu64dGzcYza1BH9f`

// This should be global object of axios to avoid the passing same configuration in every call we can move this in index.js
axios.defaults.baseURL = API_URL;

// Add request interceptor this will add the x-api-key header in every axios request
axios.interceptors.request.use(
  config => {
    const authorizationConfig = config
    authorizationConfig.headers = { "x-api-key": header_X_API_KEY }
    return authorizationConfig
  },
  error => Promise.reject(error)
);

// get shipment details based on given shipment id and request for photo with shipment details
export function getTrackShipment(shipmentId, includePhoto = false) {
  return axios
    .get(
      `shipments/trackShipment?shipment_number=${shipmentId}&include_delivery_photo=${includePhoto}`
    )
    .then(resp => resp)
    .catch(error => error)
}
